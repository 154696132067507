import Delete from "assets/img/deleteX.png";
import Edit from "assets/img/editX.png";
import View from "assets/img/showX.png";
import Tree from "assets/img/irrigationTypes/date.png";
import Pivot from "assets/img/irrigationTypes/pivot.png";
import Drip from "assets/img/irrigationTypes/dripIrr.png";
import Flood from "assets/img/irrigationTypes/immersion.png";
import Image from "next/image";
import _objI from "@/utils/_objI";
import { useTranslation } from "react-i18next";
import { authServices } from "services/auth.services";
import { isArabic } from "@/utils/isArabic";

const FarmCard = ({
  data,
  setShowModel,
  setDeleteId,
  showModel,
  viewFarm,
  editFarm,
}) => {
  const { t, i18n } = useTranslation();

  const canDelete =
    authServices?.userValue?.policy_data?.farm_integration?.actions?.includes?.(
      "delete"
    );

  const canEdit =
    authServices?.userValue?.policy_data?.farm_integration?.actions?.includes?.(
      "edit"
    );

  const handleImages = {
    pivot: Pivot,
    drip: Drip,
    flood: Flood,
  };
  return (
    <div className="bg-white text-xs lg:text-md w-full md:w-[340px] xl:w-[390px] p-2 rounded-lg relative">
      <div className="flex items-center justify-between p-5 -m-2">
        <div
          onClick={() => viewFarm(data)}
          className="flex items-center w-full h-full gap-3 cursor-pointer"
        >
          <div className="min-w-[75px] min-h-[75px] xl:w-[98px] xl:h-[97px] relative   ">
            <Image
              priority={true}
              src={
                data?.farming_type === "tree"
                  ? Tree
                  : handleImages?.[data?.irrigation_type]
              }
              alt="farm"
              className="object-fill rounded-lg"
              quality={50}
              layout={"fill"}
            />
          </div>

          <h2
            style={{
              fontFamily: isArabic(data?.farm_name)
                ? "Almarai"
                : "Baloo-regular",
            }}
            className={` p-1 text-[20px] font-semibold leading-5 text-[#1C1C1C]`}
          >
            {data?.farm_name}
          </h2>
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-3 p-3 text-[17px]">
        <div className="flex w-full">
          <span className="text-[#585858]  w-[8rem]">{t("Farm Name")}</span>
          <span
            style={{
              fontFamily: isArabic(data?.farm_name)
                ? "Almarai"
                : "Baloo-regular",
              fontSize:
                i18n.language == "en"
                  ? isArabic(data?.farm_name)
                    ? 15
                    : 17
                  : 18,
            }}
          >
            {data?.farm_name}
          </span>
        </div>

        <div className="flex w-full">
          <span className="text-[#585858] w-[8rem]">{t("Farming Type")}</span>
          {t(data?.farming_type === "tree" ? "Trees Farm" : "Crop Farm")}
        </div>

        <div className="flex w-full">
          <span className="text-[#585858] w-[8rem]">
            {t("Irrigation Type")}
          </span>
          {t(String(data?.irrigation_type))}
        </div>

        <div className="flex w-full">
          <span className="text-[#585858] w-[8rem]">{t("Farm Code")}</span>
          {data?.farm_code}
        </div>
        <div className="flex w-full">
          <span className="text-[#585858] w-[8rem]">{t("Farm Country")}</span>
          {data?.country}
        </div>
        <div className="flex w-full">
          <span className="text-[#585858] w-[8rem]">{t("Farm Address")}</span>
          <span
            style={{
              fontFamily: isArabic(data?.address) ? "Almarai" : "Baloo-regular",
              fontSize:
                i18n.language == "en"
                  ? isArabic(data?.farm_name)
                    ? 15
                    : 17
                  : 18,
            }}
          >
            {data?.address?.substring?.(0, 26)}
          </span>
        </div>
        <div className="flex w-full">
          <span className="text-[#585858] w-[8rem]">{t("GMT Offset")}</span>
          {data?.gmt_offset}
        </div>
        <div className="flex w-full">
          <span className="text-[#585858] w-[8rem]">{t("#Fields")}</span>
          {data?.fields_number}
        </div>

        {data?.farming_type === "tree" && (
          <div className="flex w-full">
            <span className="text-[#585858] w-[8rem]">{t("#Trees")}</span>
            {data?.trees ?? "-"}
          </div>
        )}

        {data?.farming_type === "crop" && (
          <div className="flex w-full h-[24px]"></div>
        )}
      </div>

      <div className="hidden mt-6 md:flex">
        <div className={`flex mb-5  text-center absolute w-full bottom-0 `}>
          <div
            onClick={() => {
              canDelete
                ? (setShowModel(!showModel), setDeleteId(data?.id))
                : null;
            }}
            className={`flex items-center justify-center gap-2 cursor-pointer ${
              canDelete ? "" : "cursor-not-allowed opacity-25"
            }`}
            style={{
              width: "calc(100% / 3)",
            }}
          >
            <div className="relative overflow-hidden w-[10.82px] h-[11.73px]">
              <Image
                priority={true}
                className="object-cover"
                layout="fill"
                src={Delete}
                alt="img"
              />
            </div>

            <div className="text-[16px]">{t("Delete")}</div>
          </div>
          <div
            className="cursor-pointer"
            style={{
              width: "calc(100% / 3)",
            }}
          >
            <div
              onClick={() => (canEdit ? editFarm(data) : null)}
              style={{
                borderLeft: "2px solid #F1F1F1",
                borderRight: "2px solid #F1F1F1",
              }}
              className={`flex items-center justify-center gap-2 cursor-pointer ${
                canEdit ? "" : "cursor-not-allowed opacity-25"
              }`}
            >
              <div className="relative overflow-hidden w-[10.82px] h-[11.73px]">
                <Image
                  priority={true}
                  className="object-cover"
                  layout="fill"
                  src={Edit}
                  alt="img"
                />
              </div>
              <div className="text-[16px]">{t("Edit")}</div>
            </div>
          </div>
          <div
            style={{
              width: "calc(100% / 3)",
            }}
            className="flex items-center justify-center cursor-pointer"
            onClick={() => viewFarm(data)}
          >
            <div className="flex items-center justify-center gap-2 ">
              <div className="relative overflow-hidden w-[14px] h-[12px]">
                <Image
                  priority={true}
                  className="object-cover"
                  layout="fill"
                  src={View}
                  alt="img"
                />
              </div>

              <div className="text-[16px]">{t("view")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmCard;

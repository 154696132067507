export const columns = (t: any) => [
  {
    Header: t("Farm Type"),
    accessor: "farming_type",
    Cell: ({ cell: { value } }) => {
      return t(value === "tree" ? "TreeXXX" : "CropXXX");
    },
  },
  {
    Header: t("Farm Code"),
    accessor: "farm_code",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("Farm Name"),
    accessor: "farm_name",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("Farm Adress"),
    accessor: "address",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("Fields"),
    accessor: "fields_number",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("No. of Trees"),
    accessor: "trees",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
];
